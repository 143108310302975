/**
 *
 * PLUGINS SCSS
 *
 */

// "bootstrap-sprockets" must be imported before "bootstrap" and "bootstrap/variables"
@import 
	"plugins/bootstrap_4/bootstrap.scss",
	// "plugins/datetimepicker/_bootstrap-datetimepicker-build.scss",
	"plugins/font-awesome/_font-awesome.scss",
	"plugins/animate/_animate.scss",
	"plugins/select2/_core.scss",
	"plugins/select2/_select2-bootstrap4.scss",
	"plugins/dashboard/_dashboard.scss";
	// "plugins/button-hover/_hover.scss",
	// "plugins/icon-sets/_icon-sets.scss",
	// "plugins/magnific/_magnific-popup.scss";
	// "plugins/slick/_slick.scss",
	// "plugins/slick/_slick-theme.scss";