/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/



/*
CONTENTS: 
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited). 
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar. 
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars. 
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars. 
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS 
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/



/* 
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE  
------------------------------------------------------------------------------------------------------------------------
*/

.mCustomScrollbar{ -ms-touch-action: pinch-zoom; touch-action: pinch-zoom; /* direct pointer events to js */ }
.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action{ -ms-touch-action: auto; touch-action: auto; }

.mCustomScrollBox{ /* contains plugin's markup */
    position: relative;
    overflow: hidden;
    height: 100%;
    max-width: 100%;
    outline: none;
    direction: ltr;
}

.mCSB_container{ /* contains the original content */
    overflow: hidden;
    width: auto;
    height: auto;
}



/* 
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR 
y-axis
------------------------------------------------------------------------------------------------------------------------
*/

.mCSB_inside > .mCSB_container{ margin-right: 30px; }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden{ margin-right: 0; } /* non-visible scrollbar */

.mCS-dir-rtl > .mCSB_inside > .mCSB_container{ /* RTL direction/left-side scrollbar */
    margin-right: 0;
    margin-left: 30px;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden{ margin-left: 0; } /* RTL direction/left-side scrollbar */

.mCSB_scrollTools{ /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
    position: absolute;
    width: 16px;
    height: auto;
    left: auto;
    top: 0;
    right: 0;
    bottom: 0;
}

.mCSB_outside + .mCSB_scrollTools{ right: -26px; } /* scrollbar position: outside */

.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools, 
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools{ /* RTL direction/left-side scrollbar */
    right: auto;
    left: 0;
}

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools{ left: -26px; } /* RTL direction/left-side scrollbar (scrollbar position: outside) */

.mCSB_scrollTools .mCSB_draggerContainer{ /* contains the draggable element and dragger rail markup */
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; 
    height: auto;
}

.mCSB_scrollTools a + .mCSB_draggerContainer{ margin: 20px 0; }

.mCSB_scrollTools .mCSB_draggerRail{
    width: 2px;
    height: 100%;
    margin: 0 auto;
    -webkit-border-radius: 16px; -moz-border-radius: 16px; border-radius: 16px;
}

.mCSB_scrollTools .mCSB_dragger{ /* the draggable element */
    cursor: pointer;
    width: 100%;
    height: 30px; /* minimum dragger height */
    z-index: 1;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ /* the dragger element */
    position: relative;
    width: 4px;
    height: 100%;
    margin: 0 auto;
    -webkit-border-radius: 16px; -moz-border-radius: 16px; border-radius: 16px;
    text-align: center;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, 
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar{ width: 12px; /* auto-expanded scrollbar */ }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail{ width: 8px; /* auto-expanded scrollbar */ }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown{
    display: block;
    position: absolute;
    height: 20px;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
}

.mCSB_scrollTools .mCSB_buttonDown{ bottom: 0; }



/* 
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR 
x-axis
------------------------------------------------------------------------------------------------------------------------
*/

.mCSB_horizontal.mCSB_inside > .mCSB_container{
    margin-right: 0;
    margin-bottom: 30px;
}

.mCSB_horizontal.mCSB_outside > .mCSB_container{ min-height: 100%; }

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden{ margin-bottom: 0; } /* non-visible scrollbar */

.mCSB_scrollTools.mCSB_scrollTools_horizontal{
    width: auto;
    height: 16px;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
}

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal{ bottom: -26px; } /* scrollbar position: outside */

.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer{ margin: 0 20px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail{
    width: 100%;
    height: 2px;
    margin: 7px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger{
    width: 30px; /* minimum dragger width */
    height: 100%;
    left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{
    width: 100%;
    height: 4px;
    margin: 6px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, 
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar{
    height: 12px; /* auto-expanded scrollbar */
    margin: 2px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail{
    height: 8px; /* auto-expanded scrollbar */
    margin: 4px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight{
    display: block;
    position: absolute;
    width: 20px;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft{ left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight{ right: 0; }



/* 
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS 
yx-axis 
------------------------------------------------------------------------------------------------------------------------
*/

.mCSB_container_wrapper{
    position: absolute;
    height: auto;
    width: auto;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-right: 30px;
    margin-bottom: 30px;
}

.mCSB_container_wrapper > .mCSB_container{
    padding-right: 30px;
    padding-bottom: 30px;
    -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical{ bottom: 20px; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal{ right: 20px; }

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical{ bottom: 0; }

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal, 
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal{ right: 0; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal{ left: 20px; }

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal{ left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper{ /* RTL direction/left-side scrollbar */
    margin-right: 0;
    margin-left: 30px;
}

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container{ padding-right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container{ padding-bottom: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden{
    margin-right: 0; /* non-visible scrollbar */
    margin-left: 0;
}

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden{ margin-bottom: 0; }



/* 
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS  
------------------------------------------------------------------------------------------------------------------------
*/

.mCSB_scrollTools, 
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight{
    -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar, /* auto-expanded scrollbar */
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail, 
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar, 
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail{
    -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, 
                margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, 
                margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s,
                opacity .2s ease-in-out, background-color .2s ease-in-out; 
    -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, 
                margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, 
                margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s,
                opacity .2s ease-in-out, background-color .2s ease-in-out; 
    -o-transition: width .2s ease-out .2s, height .2s ease-out .2s, 
                margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, 
                margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s,
                opacity .2s ease-in-out, background-color .2s ease-in-out; 
    transition: width .2s ease-out .2s, height .2s ease-out .2s, 
                margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, 
                margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s,
                opacity .2s ease-in-out, background-color .2s ease-in-out; 
}



/* 
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS  
------------------------------------------------------------------------------------------------------------------------
*/

/* 
----------------------------------------
6.1 THEMES 
----------------------------------------
*/

/* default theme ("light") */

.mCSB_scrollTools{ opacity: 0.75; filter: "alpha(opacity=75)"; -ms-filter: "alpha(opacity=75)"; }

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools{ opacity: 0; filter: "alpha(opacity=0)"; -ms-filter: "alpha(opacity=0)"; }

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools{ opacity: 1; filter: "alpha(opacity=100)"; -ms-filter: "alpha(opacity=100)"; }

.mCSB_scrollTools .mCSB_draggerRail{
    background-color: #000; background-color: rgba(0,0,0,0.4);
    filter: "alpha(opacity=40)"; -ms-filter: "alpha(opacity=40)"; 
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
    background-color: #fff; background-color: rgba(255,255,255,0.75);
    filter: "alpha(opacity=75)"; -ms-filter: "alpha(opacity=75)"; 
}

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{
    background-color: #fff; background-color: rgba(255,255,255,0.85);
    filter: "alpha(opacity=85)"; -ms-filter: "alpha(opacity=85)"; 
}
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{
    background-color: #fff; background-color: rgba(255,255,255,0.9);
    filter: "alpha(opacity=90)"; -ms-filter: "alpha(opacity=90)"; 
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight{
    background-image: url(mCSB_buttons.png); /* css sprites */
    background-repeat: no-repeat;
    opacity: 0.4; filter: "alpha(opacity=40)"; -ms-filter: "alpha(opacity=40)"; 
}

.mCSB_scrollTools .mCSB_buttonUp{
    background-position: 0 0;
    /* 
    sprites locations 
    light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
    dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
    */
}

.mCSB_scrollTools .mCSB_buttonDown{
    background-position: 0 -20px;
    /* 
    sprites locations
    light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
    dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
    */
}

.mCSB_scrollTools .mCSB_buttonLeft{
    background-position: 0 -40px;
    /* 
    sprites locations 
    light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
    dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
    */
}

.mCSB_scrollTools .mCSB_buttonRight{
    background-position: 0 -56px;
    /* 
    sprites locations 
    light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
    dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
    */
}

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover{ opacity: 0.75; filter: "alpha(opacity=75)"; -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active{ opacity: 0.9; filter: "alpha(opacity=90)"; -ms-filter: "alpha(opacity=90)"; }


/* theme: "dark" */

.mCS-dark.mCSB_scrollTools .mCSB_draggerRail{ background-color: #000; background-color: rgba(0,0,0,0.15); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.75); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: rgba(0,0,0,0.85); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: rgba(0,0,0,0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp{ background-position: -80px 0; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown{ background-position: -80px -20px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -80px -40px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight{ background-position: -80px -56px; }

/* ---------------------------------------- */



/* theme: "light-2", "dark-2" */

.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail, 
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail{
    width: 4px;
    background-color: #fff; background-color: rgba(255,255,255,0.1);
    -webkit-border-radius: 1px; -moz-border-radius: 1px; border-radius: 1px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
    width: 4px;
    background-color: #fff; background-color: rgba(255,255,255,0.75);
    -webkit-border-radius: 1px; -moz-border-radius: 1px; border-radius: 1px;
}

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{
    width: 100%;
    height: 4px;
    margin: 6px auto;
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: #fff; background-color: rgba(255,255,255,0.85); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #fff; background-color: rgba(255,255,255,0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp{ background-position: -32px 0; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown{	background-position: -32px -20px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft{	background-position: -40px -40px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight{ background-position: -40px -56px; }


/* theme: "dark-2" */

.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail{
    background-color: #000; background-color: rgba(0,0,0,0.1);
    -webkit-border-radius: 1px; -moz-border-radius: 1px; border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
    background-color: #000; background-color: rgba(0,0,0,0.75);
    -webkit-border-radius: 1px; -moz-border-radius: 1px; border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.85); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp{ background-position: -112px 0; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown{ background-position: -112px -20px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -120px -40px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight{	background-position: -120px -56px; }

/* ---------------------------------------- */



/* theme: "light-thick", "dark-thick" */

.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail, 
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail{
    width: 4px;
    background-color: #fff; background-color: rgba(255,255,255,0.1);
    -webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
    width: 6px;
    background-color: #fff; background-color: rgba(255,255,255,0.75);
    -webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px;
}

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail{
    width: 100%;
    height: 4px;
    margin: 6px 0;
}

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{
    width: 100%;
    height: 6px;
    margin: 5px auto;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: #fff; background-color: rgba(255,255,255,0.85); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #fff; background-color: rgba(255,255,255,0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp{ background-position: -16px 0; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown{	background-position: -16px -20px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft{	background-position: -20px -40px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight{ background-position: -20px -56px; }


/* theme: "dark-thick" */

.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail{
    background-color: #000; background-color: rgba(0,0,0,0.1);
    -webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
    background-color: #000; background-color: rgba(0,0,0,0.75);
    -webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.85); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp{ background-position: -96px 0; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown{ background-position: -96px -20px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -100px -40px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight{	background-position: -100px -56px; }

/* ---------------------------------------- */



/* theme: "light-thin", "dark-thin" */

.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail{ background-color: #fff; background-color: rgba(255,255,255,0.1); }

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ width: 2px; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail{ width: 100%; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{
    width: 100%;
    height: 2px;
    margin: 7px auto;
}


/* theme "dark-thin" */

.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail{ background-color: #000; background-color: rgba(0,0,0,0.15); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.75); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.85); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp{	background-position: -80px 0; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown{ background-position: -80px -20px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -80px -40px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight{ background-position: -80px -56px; }

/* ---------------------------------------- */



/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */

.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail{ background-color: #fff; background-color: rgba(255,255,255,0.15); }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger, 
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger, 
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger, 
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger{ height: 14px; }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
    width: 14px;
    margin: 0 1px;
}

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger, 
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger, 
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger, 
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger{ width: 14px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{
    height: 14px;
    margin: 1px 0;
}

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, 
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar, 
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, 
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar{
    width: 16px; /* auto-expanded scrollbar */
    height: 16px;
    margin: -1px 0;
}

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, 
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail{ width: 4px; /* auto-expanded scrollbar */ }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, 
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar, 
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, 
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar{
    height: 16px; /* auto-expanded scrollbar */
    width: 16px;
    margin: 0 -1px;
}

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, 
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail{
    height: 4px; /* auto-expanded scrollbar */
    margin: 6px 0;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp{ background-position: 0 -72px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown{ background-position: 0 -92px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft{ background-position: 0 -112px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight{ background-position: 0 -128px; }


/* theme "rounded-dark", "rounded-dots-dark" */

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.75); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail{ background-color: #000; background-color: rgba(0,0,0,0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, 
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.85); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, 
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp{ background-position: -80px -72px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown{ background-position: -80px -92px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -80px -112px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight{ background-position: -80px -128px; }


/* theme "rounded-dots", "rounded-dots-dark" */

.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail, 
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail{ width: 4px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail, 
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail, 
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail{
    background-color: transparent;
    background-position: center;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail, 
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail{
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
    background-repeat: repeat-y;
    opacity: 0.3;
    filter: "alpha(opacity=30)"; -ms-filter: "alpha(opacity=30)"; 
}

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail{
    height: 4px;
    margin: 6px 0;
    background-repeat: repeat-x;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp{ background-position: -16px -72px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown{ background-position: -16px -92px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -20px -112px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight{ background-position: -20px -128px; }


/* theme "rounded-dots-dark" */

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail{
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=");
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp{ background-position: -96px -72px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown{ background-position: -96px -92px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -100px -112px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight{ background-position: -100px -128px; }

/* ---------------------------------------- */



/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
    background-repeat: repeat-y;
    background-image: -moz-linear-gradient(left, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0.5)), color-stop(100%,rgba(255,255,255,0)));
    background-image: -webkit-linear-gradient(left, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
    background-image: -o-linear-gradient(left, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
    background-image: -ms-linear-gradient(left, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
    background-image: linear-gradient(to right, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{
    background-repeat: repeat-x;
    background-image: -moz-linear-gradient(top, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0.5)), color-stop(100%,rgba(255,255,255,0)));
    background-image: -webkit-linear-gradient(top, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
    background-image: -o-linear-gradient(top, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
    background-image: -ms-linear-gradient(top, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
    background-image: linear-gradient(to bottom, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
}


/* theme "3d", "3d-dark" */

.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger, 
.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger{ height: 70px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger, 
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger{ width: 70px; }

.mCS-3d.mCSB_scrollTools, 
.mCS-3d-dark.mCSB_scrollTools{
    opacity: 1;
    filter: "alpha(opacity=30)"; -ms-filter: "alpha(opacity=30)"; 
}

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail, 
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail, 
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ -webkit-border-radius: 16px; -moz-border-radius: 16px; border-radius: 16px; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail, 
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail{
    width: 8px;
    background-color: #000; background-color: rgba(0,0,0,0.2);
    box-shadow: inset 1px 0 1px rgba(0,0,0,0.5), inset -1px 0 1px rgba(255,255,255,0.2);
}

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 	 
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, 
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, 
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, 
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #555; }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ width: 8px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail{
    width: 100%;
    height: 8px;
    margin: 4px 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.5), inset 0 -1px 1px rgba(255,255,255,0.2);
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{
    width: 100%;
    height: 8px;
    margin: 4px auto;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp{ background-position: -32px -72px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown{ background-position: -32px -92px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -40px -112px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight{ background-position: -40px -128px; }


/* theme "3d-dark" */

.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail{
    background-color: #000; background-color: rgba(0,0,0,0.1);
    box-shadow: inset 1px 0 1px rgba(0,0,0,0.1);
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail{ box-shadow: inset 0 1px 1px rgba(0,0,0,0.1); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp{ background-position: -112px -72px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown{ background-position: -112px -92px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -120px -112px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight{	background-position: -120px -128px; }

/* ---------------------------------------- */



/* theme: "3d-thick", "3d-thick-dark" */

.mCS-3d-thick.mCSB_scrollTools, 
.mCS-3d-thick-dark.mCSB_scrollTools{
    opacity: 1;
    filter: "alpha(opacity=30)"; -ms-filter: "alpha(opacity=30)"; 
}

.mCS-3d-thick.mCSB_scrollTools, 
.mCS-3d-thick-dark.mCSB_scrollTools, 
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer, 
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer{ -webkit-border-radius: 7px; -moz-border-radius: 7px; border-radius: 7px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; }

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical, 
.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical{ right: 1px; }

.mCS-3d-thick.mCSB_scrollTools_vertical, 
.mCS-3d-thick-dark.mCSB_scrollTools_vertical{ box-shadow: inset 1px 0 1px rgba(0,0,0,0.1), inset 0 0 14px rgba(0,0,0,0.5); }

.mCS-3d-thick.mCSB_scrollTools_horizontal, 
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal{
    bottom: 1px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.1), inset 0 0 14px rgba(0,0,0,0.5);
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
    box-shadow: inset 1px 0 0 rgba(255,255,255,0.4);
    width: 12px;
    margin: 2px;
    position: absolute;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{ box-shadow: inset 0 1px 0 rgba(255,255,255,0.4); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,  
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, 
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #555; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{
    height: 12px;
    width: auto;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer{
    background-color: #000; background-color: rgba(0,0,0,0.05);
    box-shadow: inset 1px 1px 16px rgba(0,0,0,0.1);
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail{ background-color: transparent; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp{ background-position: -32px -72px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown{ background-position: -32px -92px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -40px -112px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight{	background-position: -40px -128px; }


/* theme: "3d-thick-dark" */

.mCS-3d-thick-dark.mCSB_scrollTools{ box-shadow: inset 0 0 14px rgba(0,0,0,0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal{ box-shadow: inset 0 1px 1px rgba(0,0,0,0.1), inset 0 0 14px rgba(0,0,0,0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ box-shadow: inset 1px 0 0 rgba(255,255,255,0.4), inset -1px 0 0 rgba(0,0,0,0.2); }
 
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{ box-shadow: inset 0 1px 0 rgba(255,255,255,0.4), inset 0 -1px 0 rgba(0,0,0,0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,  
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, 
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #777; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer{
    background-color: #fff; background-color: rgba(0,0,0,0.05);
    box-shadow: inset 1px 1px 16px rgba(0,0,0,0.1);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail{ background-color: transparent; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp{ background-position: -112px -72px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown{ background-position: -112px -92px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -120px -112px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight{	background-position: -120px -128px; }

/* ---------------------------------------- */



/* theme: "minimal", "minimal-dark" */

.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical, 
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical{
    right: 0; 
    margin: 12px 0; 
}

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal, 
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, 
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal, 
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal{
    bottom: 0; 
    margin: 0 12px; 
}

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical, 
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical{
    left: 0; 
    right: auto;
}

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail, 
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail{ background-color: transparent; }

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger, 
.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger{ height: 50px; }

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger, 
.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger{ width: 50px; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
    background-color: #fff; background-color: rgba(255,255,255,0.2);
    filter: "alpha(opacity=20)"; -ms-filter: "alpha(opacity=20)"; 
}

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{
    background-color: #fff; background-color: rgba(255,255,255,0.5);
    filter: "alpha(opacity=50)"; -ms-filter: "alpha(opacity=50)"; 
}


/* theme: "minimal-dark" */

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
    background-color: #000; background-color: rgba(0,0,0,0.2);
    filter: "alpha(opacity=20)"; -ms-filter: "alpha(opacity=20)"; 
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{
    background-color: #000; background-color: rgba(0,0,0,0.5);
    filter: "alpha(opacity=50)"; -ms-filter: "alpha(opacity=50)"; 
}

/* ---------------------------------------- */



/* theme "light-3", "dark-3" */

.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail, 
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail{
    width: 6px;
    background-color: #000; background-color: rgba(0,0,0,0.2);
}

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ width: 6px; }

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail{
    width: 100%;
    height: 6px;
    margin: 5px 0;
}

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, 
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail{
    width: 12px;
}

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, 
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail{
    height: 12px;
    margin: 2px 0;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp{ background-position: -32px -72px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown{ background-position: -32px -92px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -40px -112px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight{ background-position: -40px -128px; }


/* theme "dark-3" */

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.75); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.85); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail{ background-color: #000; background-color: rgba(0,0,0,0.1); }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp{ background-position: -112px -72px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown{ background-position: -112px -92px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -120px -112px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight{	background-position: -120px -128px; }

/* ---------------------------------------- */



/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */

.mCS-inset.mCSB_scrollTools .mCSB_draggerRail, 
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail, 
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail, 
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, 
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail, 
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail{
    width: 12px;
    background-color: #000; background-color: rgba(0,0,0,0.2);
}

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ 
    width: 6px;
    margin: 3px 5px;
    position: absolute;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, 
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{
    height: 6px;
    margin: 5px 3px;
    position: absolute;
    width: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail, 
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail{
    width: 100%;
    height: 12px;
    margin: 2px 0;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp, 
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp, 
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp{ background-position: -32px -72px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown, 
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown, 
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown{ background-position: -32px -92px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft, 
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft, 
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -40px -112px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight, 
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight, 
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight{ background-position: -40px -128px; }


/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.75); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, 
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, 
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.85); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, 
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, 
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.9); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail, 
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, 
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail{ background-color: #000; background-color: rgba(0,0,0,0.1); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp, 
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp, 
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp{ background-position: -112px -72px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown, 
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown, 
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown{ background-position: -112px -92px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft, 
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft, 
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft{ background-position: -120px -112px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight, 
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight, 
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight{	background-position: -120px -128px; }


/* theme "inset-2", "inset-2-dark" */

.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail, 
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail{
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: #fff;
    border-color: rgba(255,255,255,0.2);
    -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail{ border-color: #000; border-color: rgba(0,0,0,0.2); }


/* theme "inset-3", "inset-3-dark" */

.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail{ background-color: #fff; background-color: rgba(255,255,255,0.6); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail{ background-color: #000; background-color: rgba(0,0,0,0.6); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.75); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.85); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #000; background-color: rgba(0,0,0,0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ background-color: #fff; background-color: rgba(255,255,255,0.75); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{ background-color: #fff; background-color: rgba(255,255,255,0.85); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{ background-color: #fff; background-color: rgba(255,255,255,0.9); }

/* ---------------------------------------- */

/*
==========================================================
==========================================================

Bootstrapious Dashboard Template 1.2

https://bootstrapious.com/p/bootstrap-4-dashboard

==========================================================
==========================================================
*/


/*
* ==========================================================
*     GENERAL
* ==========================================================
*/

.text-bold {
    font-weight: 700;
}

.text-small {
    font-size: 0.9rem;
}

.text-xsmall {
    font-size: 0.8rem;
}

body {
    overflow-x: hidden;
}

i {
    display: inline-block;
}

span {
    display: inline-block;
}

.mt-30px {
    margin-top: 30px !important;
}

.mb-30px {
    margin-bottom: 30px !important;
}

i[class*='icon-'] {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
}

table {
    font-size: 0.9em;
    color: #666;
}

.btn-xs {
    font-size: 0.7em !important;
    padding: 2px 5px !important;
    border-radius: 2px !important;
}

.badge {
    font-weight: 400;
    border-radius: 0;
}

.badge.badge-primary {
    background: #2a4885;
}

.page-dashboard {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transition: width 0.3s linear;
    transition: width 0.3s linear;
    width: calc(100% - 230px);
    // background-color: #F4F7FA;
    min-height: 100vh;
    padding-bottom: 50px;
}

.page-dashboard.active {
    width: calc(100% - 70px);
}

.breadcrumb-holder {
    background: #eceeef;
}

.breadcrumb-holder .breadcrumb {
    padding: 15px 0;
    font-size: 0.85em;
    font-weight: 300;
    color: #999;
    margin-bottom: 0;
}

.breadcrumb-holder .breadcrumb a:hover,
.breadcrumb-holder .breadcrumb:focus {
    text-decoration: none;
}

.breadcrumb-holder .breadcrumb .container {
    padding: 0;
}

.card {
    margin-bottom: 30px;
    -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.card-header h1,
.card-header h2,
.card-header h3,
.card-header h4,
.card-header h5,
.card-header h6 {
    margin-bottom: 0;
}

.card:not([class*="text"]) p {
    color: #888;
}

.display {
    font-weight: 400 !important;
}

.section-padding {
    padding: 3rem 0;
}

.section-no-padding-bottom {
    padding-bottom: 0;
}

section header {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

@media (min-width: 768px) {
    .container-fluid {
        padding: 0 3rem;
    }
}


/*
  * ==========================================================
  *     Main NAVBAR
  * ==========================================================
  */

nav.navbar {
    background: #4870c3;
    padding: .8rem 0;
    /* Navbar Brand --------- */
    /*  Dropdowns ------------ */
    /* Toggle Button ---------- */
}

nav.navbar .side-navbar-wrapper {
    width: 100%;
    height: 100%;
}

nav.navbar .container-fluid {
    width: 100%;
}

nav.navbar .navbar-holder {
    width: 100%;
}

nav.navbar a {
    font-size: 0.8em;
    color: #fff;
    position: relative;
}

nav.navbar .badge {
    position: absolute;
    top: 0;
    right: 0;
}

nav.navbar a.logout i {
    margin-left: 5px;
}

nav.navbar small {
    font-size: 0.75em;
    color: #999;
}

nav.navbar a.navbar-brand {
    margin-left: 10px;
}

nav.navbar .brand-text {
    text-transform: uppercase;
}

nav.navbar .brand-text strong {
    font-size: 1.5em;
}

nav.navbar .nav-menu {
    margin-bottom: 0;
}

nav.navbar .dropdown-menu {
    width: 320px;
    right: 0;
    left: auto;
    float: left;
    margin-top: 16px;
    margin-bottom: 0;
    padding: 20px;
    font-size: 1.1em;
}

nav.navbar .dropdown-menu a {
    font-size: 0.75em;
    color: #666;
    padding: 10px;
}

nav.navbar .dropdown-menu a:active {
    color: #fff;
}

nav.navbar .dropdown-menu a:active span {
    color: #fff;
}

nav.navbar .dropdown-menu a span {
    color: #777;
}

nav.navbar .dropdown-menu a i {
    margin-right: 5px;
}

nav.navbar .msg-body span,
nav.navbar .msg-body strong {
    display: block;
}

nav.navbar .msg-body span {
    font-size: 0.85em;
}

nav.navbar .msg-profile {
    margin-right: 10px;
    width: 50px;
}

nav.navbar .notification-content {
    font-size: 0.8em;
}

nav.navbar a.menu-btn {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #4870c3;
    color: #fff;
    text-align: center;
    padding: 0 !important;
}

nav.navbar a.menu-btn i[class*='icon-'] {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
}

nav.navbar a.menu-btn:hover {
    background: #2a4885;
}


/*  NAVBAR MEDIAQUERIES ---------------------------------- */

@media (max-width: 575px) {
    nav.navbar .dropdown-menu {
        width: auto;
        right: -40px;
    }
    nav.navbar .notification {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

@media (min-width: 992px) {
    nav.navbar .dropdown-menu strong {
        font-weight: 400;
    }
    nav.navbar .dropdown-menu a {
        font-size: 0.9em;
    }
}


/*
  * ==========================================================
  *     SIDE NAVBAR
  * ==========================================================
  */

.side-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 230px;
    height: 100%;
    text-align: left;
    background: #4870c3;
    color: #fff;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    z-index: 999;
    overflow-y: auto;
    /*  Side navbar small ------------------------ */
}

.side-navbar .scrolled-wrapper {
    width: 100%;
    height: 100%;
}

.side-navbar.hide {
    left: -70px;
}

.side-navbar a.brand-small {
    text-transform: uppercase;
    font-weight: 500;
    padding: 0;
}

.side-navbar a.brand-small:hover,
.side-navbar a.brand-small:focus {
    background: none;
}

.side-navbar a.brand-small strong {
    font-size: 1.5rem;
}

.side-navbar li {
    position: relative;
}

.side-navbar li a {
    position: relative;
    font-size: .9rem;
    font-weight: 300;
    color: #fff;
    text-decoration: none;
    width: 100%;
    padding: 12px;
    display: block;
}

.side-navbar li a:focus,
.side-navbar li a:hover,
.side-navbar li a[aria-expanded="true"] {
    background: #2a4885;
    color: #fff;
    text-decoration: none;
}

.side-navbar li a[data-toggle="collapse"]::before {
    content: '\f104';
    display: inline-block;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: 'FontAwesome';
    position: absolute;
    top: 50%;
    right: 20px;
}

.side-navbar li a[aria-expanded="true"]::before {
    content: '\f107';
    color: #fff;
}

.side-navbar li.active>a {
    background: #2a4885;
}

.side-navbar li li.active>a {
    background: #2d9f50 !important;
}

.side-navbar li ul {
    background: #4870c3;
    position: relative;
    min-width: 100%;
    left: 0;
    padding-left: 0;
}

.side-navbar li ul a {
    padding-left: 20px;
    font-size: .85rem;
}

.side-navbar li ul.collapse a {
    border-left: 2px solid #2a4885;
}

.side-navbar li ul.collapse a:hover {
    background: #121212;
}

.side-navbar .sidenav-header {
    background: #4870c3;
    padding: 14px;
    margin-bottom: 10px;
}

.side-navbar .sidenav-header img {
    width: 50px;
    height: 50px;
}

.side-navbar .sidenav-header span {
    font-size: 0.8em;
    color: #666;
}

.side-navbar .sidenav-header h2 {
    margin: 10px 0 0;
    text-transform: uppercase;
    letter-spacing: .1em;
}

.side-navbar .sidenav-heading {
    font-weight: 700;
    color: #adb5bd;
    font-size: .9rem;
    text-transform: uppercase;
    letter-spacing: .1em;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.side-navbar i[class*='icon-'] {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
}

.side-navbar span {
    display: inline-block;
}

.side-navbar i {
    font-size: inherit;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin-right: 10px;
}

.side-navbar .badge {
    display: inline-block;
    font-size: 0.6em;
}

.side-navbar .brand-small {
    display: none;
}

.side-navbar.shrink {
    width: 70px;
    text-align: center;
}

.side-navbar.shrink .brand-small {
    display: block;
}

.side-navbar.shrink .sidenav-header-inner {
    display: none;
}

.side-navbar.shrink .sidenav-heading {
    margin-left: 0;
    font-size: .8rem;
}

.side-navbar.shrink ul li a {
    font-size: .7rem;
    padding-left: 5px;
    padding-right: 5px;
}

.side-navbar.shrink ul li a[data-toggle="collapse"]::before {
    content: '\f107';
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    position: absolute;
    top: auto;
    right: 50%;
    bottom: 0;
    left: auto;
}

.side-navbar.shrink ul li a[data-toggle="collapse"][aria-expanded="true"]::before {
    content: '\f106';
}

.side-navbar.shrink ul li ul a {
    padding-left: 10px;
    font-size: .7rem;
}

.side-navbar.shrink i {
    display: block;
    margin-right: 0;
    font-size: 1rem;
}

.side-navbar.shrink .arrow {
    float: none;
    -webkit-transform: none !important;
    transform: none !important;
}

.side-navbar.shrink .badge {
    display: none;
}


/* SIDE NAVBAR MEDIAQUERIES ------------------------------ */

@media (max-width: 1199px) {
    .page-dashboard {
        width: 100%;
    }
    .page-dashboard.active {
        width: 100%;
    }
    .page-dashboard.active-sm {
        width: calc(100% - 70px);
    }
    .side-navbar {
        left: -70px;
        width: 70px;
        text-align: center;
    }
    .side-navbar.show-sm {
        left: 0 !important;
    }
    .side-navbar .brand-small {
        display: block;
    }
    .side-navbar .sidenav-header-inner {
        display: none;
    }
    .side-navbar .sidenav-heading {
        margin-left: 0;
        font-size: .8rem;
    }
    .side-navbar ul li a {
        font-size: .7rem;
        padding-left: 5px;
        padding-right: 5px;
    }
    .side-navbar ul li a[data-toggle="collapse"]::before {
        content: '\f107';
        -webkit-transform: translateX(50%);
        transform: translateX(50%);
        position: absolute;
        top: auto;
        right: 50%;
        bottom: 0;
        left: auto;
    }
    .side-navbar ul li a[data-toggle="collapse"][aria-expanded="true"]::before {
        content: '\f106';
    }
    .side-navbar ul li ul a {
        padding-left: 10px;
        font-size: .7rem;
    }
    .side-navbar i {
        display: block;
        margin-right: 0;
        font-size: 1rem;
    }
    .side-navbar .arrow {
        float: none;
        -webkit-transform: none !important;
        transform: none !important;
    }
    .side-navbar .badge {
        display: none;
    }
}


/* Malihu Custom Scroll */

.mCSB_scrollTools {
    width: 4px;
}

.mCSB_inside>.mCSB_container {
    margin-right: 0 !important;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #2a4885;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar:hover {
    background-color: #2a4885;
}


/*
  * ==========================================================
  *      DASHBOARD COUNTS
  * ==========================================================
  */

.dashboard-counts {
    color: #333;
}

.dashboard-counts .count-title span {
    font-size: 0.8em;
    color: #aaa;
    display: block;
}

.dashboard-counts .count-title i {
    font-size: 1.5em;
    color: #2a4885;
}

.dashboard-counts .icon {
    margin-right: 10px;
}

.dashboard-counts strong {
    font-size: 0.9em;
    font-weight: 500;
    color: #555;
    display: block;
}

.dashboard-counts .count-number {
    font-size: 2.2em;
    font-weight: 300;
}


/* DASHBOARD COUNTS MEDIAQUERIES ------------------------- */

@media (max-width: 991px) {
    .dashboard-counts div[class*='col-'] {
        margin-bottom: 20px;
    }
}

@media (min-width: 768px) {
    .dashboard-counts strong {
        font-size: 1em;
    }
    .dashboard-counts span {
        font-size: 0.9em;
    }
    .dashboard-counts .count-number {
        font-size: 3em;
    }
}


/*
  * ==========================================================
  *     DASHBOARD HEADER
  * ==========================================================
  */

.dashboard-header {
    background: #fff;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.dashboard-header .check-lists {
    margin-top: 20px;
}

.dashboard-header .check-lists label {
    font-size: 0.75em;
}

.dashboard-header .pie-chart {
    position: relative;
    width: 300px;
    height: 300px;
    max-width: 100%;
    margin: 0 auto;
}

.dashboard-header p {
    font-size: 0.75rem;
    font-weight: 400;
    color: #888;
}

.dashboard-header li {
    margin-bottom: 10px;
}


/* DASHBOARD HEADER MEDIAQUERIES ------------------------- */

@media (max-width: 991px) {
    .dashboard-header {
        background: none;
        border: none;
        padding: 0;
    }
    .dashboard-header div[class*='col-'] {
        margin-bottom: 30px;
    }
    .dashboard-header div[class*='col-']:last-of-type {
        margin-bottom: 0;
    }
    .dashboard-header .card {
        background: #fff;
        padding: 30px;
        -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
        height: 100%;
    }
}

@media (min-width: 768px) {
    .dashboard-header p {
        font-size: 0.9em;
    }
    .dashboard-header h2 {
        font-size: 1.3rem;
    }
    .dashboard-header .check-lists label {
        font-size: 0.85em;
    }
}

@media (min-width: 992px) {
    .dashboard-header {
        margin-bottom: 30px;
    }
    .dashboard-header .card {
        -webkit-box-shadow: none;
        box-shadow: none;
        margin-bottom: 0;
    }
}


/*
  * ==========================================================
  *      STATISTICS
  * ==========================================================
  */

.statistics {
    /* User Activity ------------- */
}

.statistics div[class*='col-'] .card {
    padding: 20px;
    margin-bottom: 0;
    height: 100%;
}

.statistics h2 {
    margin-bottom: 20px;
}

.statistics p {
    font-size: 0.8em;
    color: #999;
    margin-bottom: 5px;
}

.statistics .income .icon {
    font-size: 3.5em;
}

.statistics .income .number {
    font-size: 2.5em;
    color: #555;
}

.statistics .income strong {
    font-size: 1.5em;
}

.statistics .data-usage canvas {
    width: 100%;
    max-width: 200px;
    border: none;
}

.statistics .data-usage strong {
    font-size: 1.5em;
    display: block;
}

.statistics .data-usage small {
    font-size: 0.85em;
    font-weight: 300;
    color: #aaa;
}

.statistics .data-usage span {
    font-size: 0.95em;
    color: #aaa;
    display: block;
}

.statistics .data-usage p {
    margin-top: 10px;
    margin-bottom: 0;
}

.statistics .user-activity .number {
    font-size: 2.5em;
    font-weight: 300;
    color: #555;
}

.statistics .user-activity .progress {
    border-radius: 0;
    background: #f5f5f5;
}

.statistics .user-activity .progress .progress-bar.bg-primary {
    background: #2a4885 !important;
}

.statistics .user-activity .page-statistics {
    margin-top: 20px;
}

.statistics .user-activity .page-statistics strong {
    display: block;
    text-transform: uppercase;
    color: #2a4885;
    font-size: 2em;
}


/* STATISTICS  MEDIAQUERIES ------------------------- */

@media (max-width: 991px) {
    .statistics div[class*='col-'] .card {
        height: auto;
        margin-bottom: 30px;
    }
    .statistics div[class*='col-']:last-of-type .card {
        margin-bottom: 0;
    }
    .statistics .data-usage {
        text-align: center;
    }
    .statistics .data-usage div[class*='col-']:first-of-type {
        padding-bottom: 20px;
    }
    .statistics .data-usage .gmpc {
        padding-bottom: 20px !important;
    }
    p {
        font-size: 0.75em;
    }
}

@media (min-width: 768px) {
    .statistics h2 {
        font-size: 1.3rem;
    }
}


/*
  * ==========================================================
  *      UPDATES WIDGETS
  * ==========================================================
  */

.updates .left-col {
    padding-right: 20px;
}

.updates .icon {
    margin-right: 10px;
}

.updates li {
    border-bottom: 1px solid #f3f3f3;
    padding: .75rem 1.25rem;
}

.updates .update-date {
    font-size: 1.3em;
    font-weight: 700;
    color: #bbb;
    display: block;
}

.updates span {
    font-size: 0.7em;
    font-weight: 300;
    color: #aaa;
    line-height: 0.5em;
    display: block;
}

.updates strong {
    font-weight: 400;
    color: #555;
    font-size: 0.9em;
}

.updates small {
    font-size: 0.75em;
}

.updates a {
    color: #555;
}

.updates a:hover,
.updates a:focus {
    color: #555;
    text-decoration: none;
}

.updates h2 {
    margin-bottom: 0;
}

.updates p {
    font-size: 0.9em;
    color: #999;
    margin-bottom: 5px;
}


/* UPDATES MEDIAQUERIES ------------------------- */

@media (max-width: 991px) {
    .updates h2 {
        font-size: 1.05rem;
    }
    .updates strong {
        font-size: 1em;
        font-weight: 400;
    }
    .updates small {
        font-size: 0.85em;
    }
    .updates .full-date {
        font-size: 0.85em;
    }
}


/*
  * ==========================================================
  *      DAILY FEEDS AND ACTIVITES WIDGETS
  * ==========================================================
  */


/*  Daily Feeds ------------------- */

.daily-feeds a.feed-profile {
    margin-right: 10px;
    width: 45px;
    height: 45px;
}

.daily-feeds a.feed-profile img {
    width: 100%;
    height: 100%;
}

.daily-feeds .badge {
    margin-right: 10px;
}

.daily-feeds strong {
    display: inline-block;
    margin-right: 5px;
}

.daily-feeds small {
    color: #999;
    display: block;
    margin-bottom: 5px;
}

.daily-feeds .message-card {
    padding: 10px;
    background: #fafafa;
    margin-left: 55px;
    margin-top: 10px;
    margin-bottom: 10px;
    -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.daily-feeds .full-date {
    line-height: 0.7em;
    margin-bottom: 10px;
}

.daily-feeds .CTAs a {
    margin-right: 5px;
}

.daily-feeds .CTAs a i {
    margin-right: 5px;
}

.daily-feeds .CTAs a:last-of-type {
    margin-right: 0;
}

.daily-feeds a.btn {
    color: #fff;
}


/* Activities  ------------------- */

.activities li {
    padding: 0;
}

.activities .date-holder {
    padding-right: 0;
}

.activities .date-holder .date {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.activities .date-holder .date span {
    font-size: 0.8em;
}

.activities .date-holder .icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: #f5f5f5;
    text-align: center;
    margin: 0;
    display: inline-block;
}

.activities .date-holder span {
    display: block;
    margin: 10px;
}

.activities .content {
    border-left: 1px solid #eee;
    border-bottom: 20px;
    padding: 10px 20px;
}

.activities .content strong {
    display: block;
    margin-right: 5px;
}


/* UPDATES WIDGET MEDIAQUERIES ------------------------- */

@media (min-width: 768px) {
    .updates strong {
        font-size: 1em;
    }
    .updates small {
        font-size: 0.8em;
    }
}


/*
  * ==========================================================
  *      PROGRESS CIRCLE
  * ==========================================================
  */

.gmpc {
    padding: 0 !important;
    width: 100% !important;
    height: auto !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #2a4885 !important;
    border-right: 1px solid #eee;
}

@media (max-width: 575px) {
    .gmpc {
        border-right: none;
        border-bottom: 1px solid #eee;
    }
}


/*
  * ==========================================================
  *       MAIN FOOTER
  * ==========================================================
  */

footer.main-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    background: #222;
    padding: 10px 0;
}

footer.main-footer p {
    font-size: 0.7em;
    color: #777;
    margin: 0;
}


/* MAIN FOOTER MEDIAQUERIES ------------------------- */

@media (max-width: 575px) {
    footer.main-footer div[class*="col-"] {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    footer.main-footer p {
        font-size: 0.9em;
    }
}


/*
  * ==========================================================
  *     POLAR CHART
  * ==========================================================
  */

.polar-chart-example .chart-container {
    width: 500px;
    max-width: 100%;
    margin: 20px auto;
}


/*
  * ==========================================================
  *     PIE CHART
  * ==========================================================
  */

.pie-chart-example .chart-container {
    width: 500px;
    max-width: 100%;
    margin: 20px auto;
}


/*
  * ==========================================================
  *     RADAR CHART
  * ==========================================================
  */

.radar-chart-example .chart-container {
    width: 500px;
    max-width: 100%;
    margin: 20px auto;
}


/* CHARTS MEDIAQUERIES -------------------------- */

@media (min-width: 768px) {
    section.charts h2 {
        font-size: 1rem;
    }
}


.form-group-material {
    position: relative;
    margin-bottom: 30px;
}

input.input-material {
    width: 100%;
    border: none;
    border-bottom: 1px solid #eee;
    padding: 10px 0;
    color: #868e96;
    font-weight: 300;
}

input.input-material:focus {
    border-color: #2a4885;
    outline: none;
}

input.input-material~label {
    color: #aaa;
    position: absolute;
    top: 14px;
    left: 0;
    cursor: text;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    font-weight: 300;
}

input.input-material~label.error {
    color: #dc3545;
    font-size: 0.75em;
    position: absolute;
    top: auto;
    bottom: -30px;
    left: 0;
}

input.input-material~label.active {
    font-size: 0.8em;
    top: -10px;
    color: #2a4885;
}

input.form-control-custom {
    display: none;
}

input.form-control-custom:checked+label::before {
    background: #2a4885 !important;
}

input.form-control-custom:checked+label::after {
    opacity: 1 !important;
}

input.form-control-custom.radio-custom+label::before {
    border-radius: 50%;
}

input.form-control-custom.radio-custom+label::after {
    content: '';
    width: 6px;
    height: 6px;
    line-height: 6px;
    background: #fff;
    border-radius: 50%;
    top: 7px;
    left: 5px;
}

input.form-control-custom[disabled]+label {
    cursor: not-allowed;
}

input.form-control-custom[disabled]+label::before,
input.form-control-custom[disabled]+label::after {
    border: 2px solid #aaa;
    cursor: not-allowed;
}

input.form-control-custom[checked]+label::before,
input.form-control-custom[checked]+label::after {
    border: none;
}

input.form-control-custom+label {
    font-size: 0.75em;
    margin-bottom: 0;
    margin-left: 0;
    color: #999;
    padding-left: 25px;
    padding-top: 2px;
    position: relative;
}

input.form-control-custom+label span {
    margin-left: 20px;
}

input.form-control-custom+label::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    background: #ddd;
    color: #fff;
    text-align: center;
    position: absolute;
    top: 2px;
    left: 0;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
    cursor: pointer;
}

input.form-control-custom+label::after {
    content: '\f00c';
    display: block;
    font-family: 'FontAwesome';
    font-size: 8px;
    color: #fff;
    position: absolute;
    top: 4px;
    left: 4px;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
    opacity: 0;
    cursor: pointer;
}

button {
    cursor: pointer;
}


/*
  * ==========================================================
  *     FORMS GENERAL
  * ==========================================================
  */

.forms .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #eee;
    margin: 20px 0 30px;
}

.forms p {
    font-size: 0.8rem;
    color: #868e96;
}

.forms small {
    color: #868e96;
}

.forms label {
    font-size: 0.8rem;
}


/* FORMS MEDIAQUERIES -------------------------- */

@media (min-width: 768px) {
    .forms h2 {
        font-size: 1rem;
    }
    .forms input.form-control {
        border-radius: 0;
    }
    .forms input.form-control::-moz-placeholder {
        font-size: 0.85em;
    }
    .forms input.form-control::-webkit-input-placeholder {
        font-size: 0.85em;
    }
    .forms input.form-control:-ms-input-placeholder {
        font-size: 0.85em;
    }
    .forms p {
        font-size: 0.9em;
    }
    .forms label {
        font-size: 0.9rem;
    }
}


/*
  * ==========================================================
  *     LOGIN PAGE
  * ==========================================================
  */

.login-page,
.register-page {
    width: 100%;
    background: #f5f5f5;
    min-height: auto;
    padding-bottom: 0;
}

.login-page .form-outer,
.register-page .form-outer {
    min-height: 100vh;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px 0;
    position: relative;
}

.login-page .copyrights,
.register-page .copyrights {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
}

.login-page .copyrights p,
.register-page .copyrights p {
    font-size: 0.6em;
    font-weight: 300;
    margin-bottom: 10px;
}

.login-page .form-inner,
.register-page .form-inner {
    padding: 40px 30px;
    background: #fff;
    -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.login-page .logo,
.register-page .logo {
    font-size: 2em;
    font-weight: 500;
}

.login-page .logo span,
.register-page .logo span {
    color: #ccc;
}

.login-page p,
.register-page p {
    font-size: 0.8em;
    font-weight: 300;
    color: #aaa;
    margin-bottom: 0;
}

.login-page form,
.register-page form {
    margin: 2rem auto 1rem;
    max-width: 300px;
}

.login-page a.forgot-pass,
.login-page a.signup,
.register-page a.forgot-pass,
.register-page a.signup {
    font-size: 0.65em;
    color: #66b0ff;
}

.login-page a.forgot-pass:hover,
.login-page a.forgot-pass:focus,
.login-page a.signup:hover,
.login-page a.signup:focus,
.register-page a.forgot-pass:hover,
.register-page a.forgot-pass:focus,
.register-page a.signup:hover,
.register-page a.signup:focus {
    color: #66b0ff;
    text-decoration: none;
}

.login-page a.forgot-pass,
.register-page a.forgot-pass {
    display: block;
}

.login-page a.create-account,
.register-page a.create-account {
    border-radius: 50px;
    font-size: 0.75em;
    margin-top: 10px;
    color: #999;
    text-transform: uppercase;
}

.login-page .terms-conditions,
.register-page .terms-conditions {
    margin-top: 20px;
}

.login-page small,
.register-page small {
    font-size: 0.65em;
    color: #aaa;
    line-height: 0.9em;
}


/* LOGIN MEDIAQUERIES -------------------------- */

@media (min-width: 768px) {
    .login-page p,
    .register-page p {
        font-size: 0.9em;
    }
    .login-page form label.label-custom,
    .register-page form label.label-custom {
        font-size: 0.9em;
    }
    .login-page form small,
    .register-page form small {
        font-size: 0.8em;
    }
    .login-page .terms-conditions label,
    .register-page .terms-conditions label {
        font-size: 0.9em;
    }
    .login-page a.forgot-pass,
    .login-page a.signup,
    .register-page a.forgot-pass,
    .register-page a.signup {
        font-size: 0.8em;
    }
    .login-page small,
    .register-page small {
        font-size: 0.8em;
    }
    .login-page .copyrights p,
    .register-page .copyrights p {
        font-size: 0.85em;
        font-weight: 400;
    }
}


/*
  
  =====================
  STYLE SWITCHER FOR DEMO
  =====================
  
  */

#style-switch-button {
    position: fixed;
    top: 120px;
    right: 0px;
    border-radius: 0;
    z-index: 2;
}

#style-switch {
    width: 300px;
    padding: 20px;
    position: fixed;
    top: 160px;
    right: 0px;
    background: #fff;
    border: solid 1px #ced4da;
    z-index: 2000;
}

#style-switch h4 {
    color: #495057;
}


/* ========================================= 
     THEMING OF BOOTSTRAP COMPONENTS           
     ========================================= */


/*
   * 1. NAVBAR
   */

.navbar {
    padding: 0.5rem 1rem;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
}

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    border: 1px solid transparent;
    border-radius: 0;
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-dark .navbar-brand {
    color: #fff;
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
}

.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.1;
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.1;
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.1;
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.1;
}

hr {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
    font-size: 80%;
    font-weight: 400;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

.blockquote {
    padding: 0.5rem 1rem;
    margin-bottom: 2rem;
    font-size: 1.25rem;
    border-left: 5px solid #2a4885;
}

.blockquote-footer {
    color: #868e96;
}

.blockquote-footer::before {
    content: "\2014 \00A0";
}

.text-primary {
    color: #2a4885 !important;
}

a.text-primary:focus,
a.text-primary:hover {
    color: #288b46 !important;
}


/*
   * 4. PAGINATION
   */

.page-item:first-child .page-link {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.page-item:last-child .page-link {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.page-item.active .page-link {
    color: #fff;
    background-color: #2a4885;
    border-color: #2a4885;
}

.page-item.disabled .page-link {
    color: #868e96;
    background-color: #fff;
    border-color: #ddd;
}

.page-link {
    padding: 0.5rem 0.75rem;
    line-height: 1.25;
    color: #2a4885;
    background-color: #fff;
    border: 1px solid #ddd;
}

.page-link:focus,
.page-link:hover {
    color: #22773c;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #ddd;
    outline: 0;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}


/*
  * 5. DROPDOWNS
  */

.dropdown-menu {
    z-index: 1000;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
}

.dropdown-item {
    padding: 0.25rem 1.5rem;
    color: #212529;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #16181b;
    background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    background-color: #2a4885;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #868e96;
}

.dropdown-header {
    padding: 0.5rem 1.5rem;
    font-size: 0.875rem;
    color: #868e96;
}


/*
  * 6. UTILITIES
  */

.bg-primary {
    background-color: #2a4885 !important;
}

a.bg-primary:focus,
a.bg-primary:hover {
    background-color: #288b46 !important;
}

.bg-secondary {
    background-color: #868e96 !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover {
    background-color: #6c757d !important;
}

.bg-success {
    background-color: #28a745 !important;
}

a.bg-success:focus,
a.bg-success:hover {
    background-color: #1e7e34 !important;
}

.bg-info {
    background-color: #17a2b8 !important;
}

a.bg-info:focus,
a.bg-info:hover {
    background-color: #117a8b !important;
}

.bg-warning {
    background-color: #ffc107 !important;
}

a.bg-warning:focus,
a.bg-warning:hover {
    background-color: #d39e00 !important;
}

.bg-danger {
    background-color: #dc3545 !important;
}

a.bg-danger:focus,
a.bg-danger:hover {
    background-color: #bd2130 !important;
}

.bg-light {
    background-color: #f8f9fa !important;
}

a.bg-light:focus,
a.bg-light:hover {
    background-color: #dae0e5 !important;
}

.bg-dark {
    background-color: #343a40 !important;
}

a.bg-dark:focus,
a.bg-dark:hover {
    background-color: #1d2124 !important;
}

.border-primary {
    border-color: #2a4885 !important;
}

.border-secondary {
    border-color: #868e96 !important;
}

.border-success {
    border-color: #28a745 !important;
}

.border-info {
    border-color: #17a2b8 !important;
}

.border-warning {
    border-color: #ffc107 !important;
}

.border-danger {
    border-color: #dc3545 !important;
}

.border-light {
    border-color: #f8f9fa !important;
}

.border-dark {
    border-color: #343a40 !important;
}

.text-primary {
    color: #2a4885 !important;
}

a.text-primary:focus,
a.text-primary:hover {
    color: #288b46 !important;
}

.text-secondary {
    color: #868e96 !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
    color: #6c757d !important;
}

.text-success {
    color: #28a745 !important;
}

a.text-success:focus,
a.text-success:hover {
    color: #1e7e34 !important;
}

.text-info {
    color: #17a2b8 !important;
}

a.text-info:focus,
a.text-info:hover {
    color: #117a8b !important;
}

.text-warning {
    color: #ffc107 !important;
}

a.text-warning:focus,
a.text-warning:hover {
    color: #d39e00 !important;
}

.text-danger {
    color: #dc3545 !important;
}

a.text-danger:focus,
a.text-danger:hover {
    color: #bd2130 !important;
}

.text-light {
    color: #f8f9fa !important;
}

a.text-light:focus,
a.text-light:hover {
    color: #dae0e5 !important;
}

.text-dark {
    color: #343a40 !important;
}

a.text-dark:focus,
a.text-dark:hover {
    color: #1d2124 !important;
}

.badge-primary {
    color: color-yiq(#2a4885);
    background-color: #2a4885;
}

.badge-primary[href]:focus,
.badge-primary[href]:hover {
    color: color-yiq(#2a4885);
    text-decoration: none;
    background-color: #288b46;
}

.badge-secondary {
    color: color-yiq(#868e96);
    background-color: #868e96;
}

.badge-secondary[href]:focus,
.badge-secondary[href]:hover {
    color: color-yiq(#868e96);
    text-decoration: none;
    background-color: #6c757d;
}

.badge-success {
    color: color-yiq(#28a745);
    background-color: #28a745;
}

.badge-success[href]:focus,
.badge-success[href]:hover {
    color: color-yiq(#28a745);
    text-decoration: none;
    background-color: #1e7e34;
}

.badge-info {
    color: color-yiq(#17a2b8);
    background-color: #17a2b8;
}

.badge-info[href]:focus,
.badge-info[href]:hover {
    color: color-yiq(#17a2b8);
    text-decoration: none;
    background-color: #117a8b;
}

.badge-warning {
    color: color-yiq(#ffc107);
    background-color: #ffc107;
}

.badge-warning[href]:focus,
.badge-warning[href]:hover {
    color: color-yiq(#ffc107);
    text-decoration: none;
    background-color: #d39e00;
}

.badge-danger {
    color: color-yiq(#dc3545);
    background-color: #dc3545;
}

.badge-danger[href]:focus,
.badge-danger[href]:hover {
    color: color-yiq(#dc3545);
    text-decoration: none;
    background-color: #bd2130;
}

.badge-light {
    color: color-yiq(#f8f9fa);
    background-color: #f8f9fa;
}

.badge-light[href]:focus,
.badge-light[href]:hover {
    color: color-yiq(#f8f9fa);
    text-decoration: none;
    background-color: #dae0e5;
}

.badge-dark {
    color: color-yiq(#343a40);
    background-color: #343a40;
}

.badge-dark[href]:focus,
.badge-dark[href]:hover {
    color: color-yiq(#343a40);
    text-decoration: none;
    background-color: #1d2124;
}


/*
  * 7.CODE
  */

code {
    padding: 0.2rem 0.4rem;
    font-size: 90%;
    color: #bd4147;
    background-color: #f8f9fa;
    border-radius: 0;
}

a>code {
    padding: 0;
    color: inherit;
    background-color: inherit;
}


/*
  * 8. NAV
  */

.nav-link {
    padding: 0.5rem 1rem;
}

.nav-link.disabled {
    color: #868e96;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #e9ecef;
}

.nav-tabs .nav-link.disabled {
    color: #868e96;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
}

.nav-pills .nav-link {
    border-radius: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #2a4885;
}


/*
  * 9. CARD
  */

.card {
    background-color: #fff;
    border: 0 solid #eee;
    border-radius: 0;
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.card-body {
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 1rem;
}

.card-subtitle {
    margin-top: -0.5rem;
}

.card-link+.card-link {
    margin-left: 1.25rem;
}

.card-header {
    padding: 1rem 1.25rem;
    background-color: #fff;
    border-bottom: 1px solid #eee;
}

.card-header:first-child {
    border-radius: 0 0 0 0;
}

.card-header-transparent {
    background-color: rgba(0, 0, 0, 0.3);
    border-bottom: none;
}

.card-footer {
    padding: 1rem 1.25rem;
    background-color: #f8f9fa;
    border-top: 1px solid #eee;
}

.card-footer:last-child {
    border-radius: 0 0 0 0;
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -1rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    padding: 1.25rem;
}

.card-img-overlay-opacity {
    background: rgba(0, 0, 0, 0.2);
}

.card-img {
    border-radius: 0;
}

.card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.card-img-bottom {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.card-deck .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-deck {
        margin-right: -15px;
        margin-left: -15px;
    }
    .card-deck .card {
        margin-right: 15px;
        margin-left: 15px;
    }
}